<template>
    <v-group ref="group">
        <v-image ref="image" :config="blurRectConfig" />
        <v-rect :config="boxConfig" />
        <v-rect :config="hitBoxConfig" @mouseover="onMouseOver" @mouseout="onMouseOut" @focusin="onFocusIn" @focusout="onFocusOut" tabindex="0" />
        <v-label v-if="label" :config="labelConfig" @mouseover="onMouseOver" @mouseout="onMouseOut" @focusin="onFocusIn" @focusout="onFocusOut">
            <v-tag :config="labelTagConfig" />
            <v-text :config="labelTextConfig" />
        </v-label>
        <v-line v-if="showTrail" :config="lineConfig" />
        <v-group v-if="showPPECheck">
            <v-rect v-if="isActive && violationsConfig" :config="violationsConfig" />
            <v-group v-if="isActive && violationsTextConfig">
                <v-path
                    v-for="(vConfig, index) in violationsTextConfig"
                    :key="`vconfig-${index}`"
                    :config="vConfig"
                />
            </v-group>
        </v-group>
    </v-group>
</template>

<script>
import { capitalize } from 'lodash';
import { COLORS } from '@/utils/constants';
import { getPPEDetections } from '@/utils/hs/helpers';
import { convertToCanvasRect, convertToCanvasLine } from '@/utils/canvas';
import { HS_VIOLATION_ICONS, HS_VIOLATION_SUCCESS, HS_VIOLATION_EXCLUSION } from '@/utils/hs/icons';

export default {
    props: {
        result: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            required: true,
        },
        translate: {
            type: Array,
            default: () => [0, 0],
        },
        imageConfig: {
            type: Object,
            required: false,
            default: () => {},
        },
        showSpeed: {
            type: Boolean,
            default: true,
        },
        showPPECheck: {
            type: Boolean,
            default: true,
        },
        showTrail: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isActive: true,
            violationBoxSize: 40,
            violationIconSize: 24,
            violationBorderWidth: 4,
            detectionBorderWidth: 2,
            animation: null,
            layer: null,
        };
    },

    computed: {
        strokeWidth() {
            return this.isViolation ? this.violationBorderWidth : this.detectionBorderWidth;
        },
        stroke() {
            return this.isHighlighted ? COLORS.highlight : (this.isViolation ? COLORS.red : COLORS.green);
        },

        strokeHalfWidth() {
            return this.strokeWidth / 2;
        },

        isViolation() {
            return this.result.isViolation;
        },

        violationCount() {
            return this.violations.length;
        },

        violations() {
            return getPPEDetections(this.result);
        },

        bb() {
            const bb = this.convertBB(this.result.bb);
            return {
                x: bb[0],
                y: bb[1],
                width: bb[2],
                height: bb[3],
                cornerRadius: 10,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
            };
        },

        line() {
            if (this.result.line === undefined) {
                return {
                    points: [],
                };
            }

            return {
                points: convertToCanvasLine({
                    str: this.result.line.points,
                    scale: this.scale,
                    translate: this.translate,
                }),
            };
        },

        blurRectConfig() {
            if (this.result.object_class.toLowerCase() !== 'person') return {};
            const preBB = convertToCanvasRect({
                str: [
                    this.result.bb.l,
                    this.result.bb.t,
                    this.result.bb.r,
                    this.result.bb.b,
                ],
                scale: 1,
                translate: null,
            });
            const bb = {
                x: preBB[0],
                y: preBB[1],
                width: preBB[2],
                height: preBB[3],
            };

            const c = {
                ...this.imageConfig,
                x: this.bb.x,
                y: this.bb.y,
                height: this.bb.height / 3,
                width: this.bb.width,
                cropX: bb.x,
                cropY: bb.y,
                cropWidth: bb.width,
                cropHeight: bb.height / 3,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
            };
            return c;
        },

        boxConfig() {
            return {
                ...this.bb,
                fill: this.isViolation ? `${COLORS.red}2F` : `${COLORS.green}77`,
                stroke: this.stroke,
                strokeWidth: this.strokeWidth,
                // dash: this.isViolation ? [] : [5, 5],
                hitFunc(ctx) {
                    ctx.beginPath();
                    ctx.closePath();
                },

            };
        },

        lineConfig() {
            return {
                ...this.line,
                stroke: COLORS.blue,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 0.5,
            };
        },

        hitBoxConfig() {
            return {
                ...this.bb,
                stroke: this.stroke,
                strokeWidth: this.strokeWidth,
                fillEnabled: false,
            };
        },

        label() {
            return capitalize(this.result.object_class || 'unknown') + (Object.prototype.hasOwnProperty.call(this.result, 'speed') && this.showSpeed ? ` ( ${this.result.speed === 1 ? 0 : this.result.speed} KM/H )` : '')
                + (this.result.plate && this.result.object_class !== 'forklift' ? (`\nPlate: ${this.result.plate !== 'None' ? this.result.plate : 'Unknown'}`) : '');
        },

        labelConfig() {
            const hasPlate = this.result.plate && this.result.plate !== 'Unknown';
            const height = hasPlate ? 30 : 18;

            const config = {
                x: this.bb.x - this.strokeHalfWidth,
                y: this.bb.y - height,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
            };

            config.y = this.bb.y - height - this.strokeHalfWidth;

            if (config.y < 0) {
                config.y = this.strokeHalfWidth;
            }

            return config;
        },

        labelTagConfig() {
            return {
                fill: `${COLORS.white}BB`,
            };
        },

        labelTextConfig() {
            return {
                text: this.label,
                fontFamily: 'replica',
                fontSize: 12,
                padding: 2,
                fill: COLORS.black,
            };
        },

        violationsConfig() {
            let height = this.violationBoxSize;
            if (this.violationCount > 1) {
                height = this.violationBoxSize * 2;
            }

            const config = {
                x: this.bb.x - this.violationBoxSize - this.strokeHalfWidth,
                y: this.bb.y - this.strokeHalfWidth,
                width: this.violationBoxSize,
                height,
                fill: COLORS.grey,
                opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
                hitFunc(ctx) {
                    ctx.beginPath();
                    ctx.closePath();
                },
            };

            if (config.x < 0) {
                config.x = this.bb.x + this.bb.width + this.strokeHalfWidth;
            }

            return config;
        },

        violationsTextConfig() {
            const configs = [];

            const x = this.violationsConfig.x
                // - this.violationBoxSize
                + (this.violationBoxSize - this.violationIconSize) / 2;
            // - this.strokeHalfWidth;

            if (this.result.object_class !== 'Person' && this.isViolation) {
                configs.push({
                    x,
                    y: this.bb.y + (this.violationBoxSize - this.violationIconSize) / 2 - 2,
                    width: this.violationIconSize,
                    height: this.violationIconSize,
                    fill: COLORS.red,
                    data: HS_VIOLATION_EXCLUSION,
                    opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
                });
                return configs;
            }

            if (!this.isViolation) {
                configs.push({
                    x,
                    y: this.bb.y + (this.violationBoxSize - this.violationIconSize) / 2 - 2,
                    width: this.violationIconSize,
                    height: this.violationIconSize,
                    fill: COLORS.green,
                    data: HS_VIOLATION_SUCCESS,
                    opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
                });
                return configs;
            }

            this.violations.forEach((name, index) => {
                const y = this.bb.y
                    + (index * this.violationBoxSize)
                    + (this.violationBoxSize - this.violationIconSize) / 2 - 2;
                configs.push({
                    x,
                    y,
                    width: this.violationIconSize,
                    height: this.violationIconSize,
                    fill: COLORS.red,
                    data: HS_VIOLATION_ICONS[name],
                    opacity: this.result.opacity !== undefined ? this.result.opacity : 1,
                });
            });

            return configs;
        },

        node() {
            return this.$refs.group.getNode();
        },

        getSelectedObjectIds() {
            return this.$store.state.canvas.selectedObjectIds;
        },

        isHighlighted() {
            return this.getSelectedObjectIds.find((id) => id === this.result.id) !== undefined;
        },
    },

    watch: {
        isActive: {
            handler(val) {
                this.$nextTick(() => {
                    if (!this.node) {
                        return;
                    }
                    if (val) {
                        this.node.setZIndex(10);
                    } else {
                        this.node.setZIndex(1);
                    }
                });
            },
        },
    },

    methods: {
        convertBB(bb) {
            return convertToCanvasRect({
                str: [
                    bb.l,
                    bb.t,
                    bb.r,
                    bb.b,
                ],
                scale: this.scale,
                translate: this.translate,
            });
        },

        onMouseOver() {
            this.isActive = true;
        },

        onMouseOut() {
            this.isActive = false;
        },

        onFocusIn() {
            this.onMouseOver();
        },

        onFocusOut() {
            this.onMouseOut();
        },
    },
};
</script>
