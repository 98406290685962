/**
 * ROI Utils
 * Functions to handle ROI related operations
 */
import { ROI_TYPES } from '@/utils/constants';

export function getRoiColor(roiType) {
    switch (roiType) {
        case ROI_TYPES[0]:
            return '#F7E987';
        case ROI_TYPES[1]:
            return '#F6B26BFF';
        case ROI_TYPES[2]:
            return '#FF6B6B';
        case ROI_TYPES[3]:
            return '#59D7FF';
        case ROI_TYPES[4]:
            return '#A6ACBE';
        case ROI_TYPES[5]:
            return '#36F4BEFF';
        default:
            throw new Error(`Invalid ROI type: ${roiType}`);
    }
}

export function getRoiDescription(roiType) {
    switch (roiType) {
        case ROI_TYPES[0]:
            return 'Area where all activity is recorded';
        case ROI_TYPES[1]:
            return 'Mandatory PPE area';
        case ROI_TYPES[2]:
            return 'No entry permitted';
        case ROI_TYPES[3]:
            return 'Speed monitored vehicle path';
        case ROI_TYPES[4]:
            return 'Automated pixelation area';
        case ROI_TYPES[5]:
            return 'Approved vehicle-pedestrian path';
        default:
            throw new Error(`Invalid ROI type: ${roiType}`);
    }
}

export function getRoiLabel(roiType) {
    switch (roiType) {
        case ROI_TYPES[0]:
            return 'Detection Region';
        case ROI_TYPES[1]:
            return 'PPE Zone';
        case ROI_TYPES[2]:
            return 'Exclusion Zone';
        case ROI_TYPES[3]:
            return 'Vehicle Track';
        case ROI_TYPES[4]:
            return 'Privacy Mask';
        case ROI_TYPES[5]:
            return 'Safe Zone';
        default:
            throw new Error(`Invalid ROI type: ${roiType}`);
    }
}
