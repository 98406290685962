<template>
    <be-widget
        :contentClasses="['mapbox-map', 'sites-map']"
        :is-loading="isLoading"
    >
        <template v-slot:heading>
            Geographic Distribution
        </template>
        <template v-slot:content>
            <div class="dist">
                <div class="mapbox-map sites-map">
                    <mgl-map
                        v-if="shouldLoad"
                        class="mapbox-map__map sites-map__map"
                        ref="map"
                        v-bind="mapboxOptions"
                        :map-style="mapStyle"
                        @load="onMapLoaded"
                    >
                        <template v-for="(marker, index) in camerasGeoJson">
                            <h-s-camera-marker
                                v-if="marker.properties.camera && (marker.properties.displayHigh || marker.properties.displayMedium)"
                                :key="`marker${index}`"
                                :item="marker.properties.camera"
                                :sizePercentage="marker.properties.sizePercentage"
                                :displayHigh="marker.properties.displayHigh"
                                :displayMedium="marker.properties.displayMedium"
                                :marker="marker"
                            />
                        </template>

                        <mgl-navigation-control position="bottom-right" :showZoom="false" />
                    </mgl-map>
                </div>
                <div>
                    <div class="dist__inner">
                        <button
                            type="button"
                            @click="() => onLegendClicked('high')"
                            ref="legend-high"
                            class="dist__row">
                            <div class="dist__row_icon">
                                <div class="dist__icon is-danger">
                                    <b-icon :icon="`blank`" />
                                </div>
                                <div class="label-sub-text">Elevated</div>
                            </div>
                        </button>
                        <button
                            type="button"
                            @click="() => onLegendClicked('medium')"
                            ref="legend-medium"
                            class="dist__row">
                            <div class="dist__row_icon">
                                <div class="dist__icon is-warning">
                                    <b-icon :icon="`blank`" />
                                </div>
                                <div class="label-sub-text">Moderate</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </be-widget>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglNavigationControl } from 'v-mapbox';
import { mapState, mapActions, mapGetters } from 'vuex';
import { getMapStyle, MAPBOX_OPTIONS } from '@/utils/map';
import mapMixin from '@/mixins/mapMixin';
import HSCameraMarker from '@/components/local/sites/hs/HSCameraMarker';

export default {
    name: 'GeographicDistribution',

    mixins: [mapMixin],

    components: {
        MglNavigationControl,
        HSCameraMarker,
    },

    data() {
        return {
            shouldLoad: false,
            mapboxOptions: {
                ...MAPBOX_OPTIONS,
                center: [174.8300225, -36.9435007],
                zoom: 17,
                attributionControl: false,
                dragPan: false,
                scrollZoom: false,
                boxZoom: false,
                doubleClickZoom: false,
                touchPitch: false,
                touchZoomRotate: false,
                maxZoom: 17.4,
            },
            displayHigh: true,
            displayMedium: true,
        };
    },

    watch: {
        getFilterHash() {
            this.onRefreshData();
        },
        countData: {
            handler() {
                this.setMapBounds();
            },
            deep: true,
        },
        mapBounds: {
            handler() {
                this.setMapBounds();
            },
            deep: true,
        },
    },

    mounted() {
        this.shouldLoad = true;
    },

    computed: {
        ...mapGetters('hs/riskLevelCamerasSummary', ['isLoading']),
        ...mapState('hs/riskLevelCamerasSummary', ['countData', 'camerasCoordinates']),
        ...mapGetters('dataFilters', ['getFilterHash']),

        mapStyle() {
            return getMapStyle('satellite');
        },

        camerasGeoJson() {
            let sortedCountData = this.countData
                .toSorted((a, b) => (b.medium + b.high) - (a.medium + a.high));
            let difference = sortedCountData[0].medium
                + sortedCountData[0].high
                - sortedCountData[sortedCountData.length - 1].medium
                - sortedCountData[sortedCountData.length - 1].high;

            if (this.displayHigh && !this.displayMedium) {
                sortedCountData = this.countData.toSorted((a, b) => b.high - a.high);
                difference = sortedCountData[0].high - sortedCountData[sortedCountData.length - 1].high;
            } else if (!this.displayHigh && this.displayMedium) {
                sortedCountData = this.countData.toSorted((a, b) => b.medium - a.medium);
                difference = sortedCountData[0].medium - sortedCountData[sortedCountData.length - 1].medium;
            }

            return sortedCountData
                .map((camera) => {
                    let sizePercentage = ((camera.medium + camera.high)
                        - (sortedCountData[sortedCountData.length - 1].medium
                            + sortedCountData[sortedCountData.length - 1].high)) / difference;

                    if (this.displayHigh && !this.displayMedium) {
                        sizePercentage = (camera.high
                            - sortedCountData[sortedCountData.length - 1].high) / difference;
                    } else if (!this.displayHigh && this.displayMedium) {
                        sizePercentage = (camera.medium
                            - sortedCountData[sortedCountData.length - 1].medium) / difference;
                    }

                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: this.getCameraBounds(camera.metadata),
                        },
                        properties: {
                            // cluster: true,
                            camera,
                            sizePercentage,
                            displayHigh: this.displayHigh,
                            displayMedium: this.displayMedium,
                        },
                    };
                });
        },

        mapBounds() {
            // if (!this.countData.length) {
            if (!Object.entries(this.camerasCoordinates).length) {
                return null;
            }

            // this.countData.reduce((bnds, camera) => {
            return Object.values(this.camerasCoordinates).reduce((bnds, camera) => {
                bnds.extend(this.getCameraBounds(camera));
                return bnds;
            }, new Mapbox.LngLatBounds());
        },
    },

    methods: {
        ...mapActions('hs/riskLevelCamerasSummary', ['loadData']),

        onRefreshData() {
            this.loadData();
        },

        onMapLoaded() {
            this.loadData();
            this.setMapBounds();
        },

        setMapBounds() {
            if (this.mapBounds) {
                this.$refs.map.map.fitBounds(this.mapBounds, {
                    duration: 0,
                });

                this.$refs.map.map.setMaxBounds(this.mapBounds);
            }
        },

        getCameraBounds(camera) {
            return [
                camera.lng,
                camera.lat,
            ];
        },

        onLegendClicked(category) {
            if (this.$refs[`legend-${category}`].classList.contains('is-removed')) {
                this.$refs[`legend-${category}`].classList.remove('is-removed');
            } else {
                this.$refs[`legend-${category}`].classList.add('is-removed');
            }

            if (category === 'high') {
                this.displayHigh = !this.displayHigh;
            } else if (category === 'medium') {
                this.displayMedium = !this.displayMedium;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import url('https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css');

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.is-removed {
    text-decoration: line-through;
}

.sites-map {
    height: 50vh;
    width: 100%;
}

.dist {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;

    &__inner {
        padding: 10px;
        @include mobile {
            padding: 5px;
        }
    }

    &__row_icon {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    &__row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    &__footer {
        @include tablet {
            display: flex;
            align-items: stretch;
        }

        &__cta {
            @include tablet {
                flex: 1;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    &__icon {
        position: relative;
        margin: 10px 0;
        width: 25px;
        height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-rounded;

        &.is-success {
            background: $low;
            color: $low-invert;
        }

        &.is-warning {
            background: $moderate;
            color: $moderate-invert;
        }

        &.is-danger {
            background: $critical;
            color: $critical-invert;
        }

        &.is-critical {
            background: $critical;
            color: $critical-invert;
        }

        &.is-info {
            background: $info;
            color: $info-invert;
        }

        &.is-light {
            background: $light;
            color: $light-invert;
        }
    }
}
</style>
