import { Line, mixins } from 'vue-chartjs';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

const { reactiveProp } = mixins;

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
    // Overwriting base render method with actual data.
        this.renderChart(this.chartData, this.options);
        this.addPlugin(chartjsPluginAnnotation);
    },
};
