import summary from './summary';
import dailysummary from './dailysummary';
import explorer from './explorer/index';
import observations from './observations/index';
import objectClasses from './objectClasses';
import proximity from './explorer/detections/proximity/index';
import riskLevel from './riskLevel';
import riskLevelSummary from './riskLevelSummary';
import riskLevelCamerasSummary from './riskLevelCamerasSummary';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    resetState({ commit, dispatch }) {
        commit('explorer/resetState');
        commit('summary/resetState');
        commit('dailysummary/resetState');
        return dispatch('observations/resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        summary,
        dailysummary,
        explorer,
        observations,
        objectClasses,
        proximity,
        riskLevel,
        riskLevelSummary,
        riskLevelCamerasSummary,
    },
};
