<template>
    <div class="field has-addons">
        <div
            class="control"
            v-for="(val, index) in values"
            :key="`control${index}`"
        >
            <b-button
                :active="false"
                :focused="false"
                class="be-button is-toggle"
                :class="getValueClases(val.handle)"
                @click="toggle(val.handle)"
            >
                {{ val.label }}
            </b-button>
        </div>
    </div>
</template>

<script>
import modelMixin from '@/mixins/modelMixin';

export default {
    mixins: [modelMixin],

    props: {
        values: {
            type: Array,
            default: () => [
                {
                    handle: 'images',
                    label: 'Images received',
                },
                {
                    handle: 'detections',
                    label: 'Unique events',
                },
            ],
        },
        value: {
            type: String,
            required: true,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        toggle(value) {
            this.computedValue = value;
        },

        getValueClases(handle) {
            const classes = [];
            if (this.computedValue === handle) {
                classes.push('is-toggle-active');
            } else {
                classes.push('is-toggle-inactive-hs');
            }
            if (this.isSmall) {
                classes.push('is-small');
            }
            return classes;
        },
    },
};
</script>

<style lang="scss" scoped>

.is-toggle-inactive-hs {
    background: #59637C;
    border-color: #37404C;
    color: #fff;
}
</style>
