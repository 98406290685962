import axios from 'axios';
import { get } from 'lodash';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import {
    buildStartEndDateUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { hsDetectionsTransformer } from '@/utils/transformers/hs/detections';
import ppe from './ppe';
import zones from './zones';

const state = getDefaultDataState({ hasPagination: true });

const getters = {
    ...getDefaultDataGetters({ hasPagination: true }),

    imageIds(state, getters) {
        return getters.formattedApiResults
            .map((row) => get(row, 'image.id'))
            .filter((r) => r);
    },

    formattedApiResults(state, getters) {
        return hsDetectionsTransformer(getters.apiResults);
    },

    apiUrlParams(state, getters, rootState, rootGetters) {
        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getFilterSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            perPage: state.perPage,
            page: state.currentPage,
        });
        return params;
    },

    apiUrl(state, getters, rootState) {
        return buildStartEndDateUrl({
            path: 'hsafety/detections',
            startDate: rootState.dataFilters.startDate,
            endDate: rootState.dataFilters.endDate,
        });
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: true }),
};

const actions = {
    async loadData({ state, commit, getters, dispatch }) {
        const params = getters.apiUrlParams;
        const path = getters.apiUrl;
        // const includeCritical = rootGetters['dataFilters/shouldIncludeCritical'];

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });

        try {
            // Make the primary request
            const mainResponse = await axios({
                url: getApiUrl({ path }),
                params,
            });

            /*
            let combinedData = mainResponse.data;

            // If high risk is selected, make an additional request for critical

            if (includeCritical) {
                const criticalParams = {
                    ...params,
                    risk: 'critical',
                };

                const criticalResponse = await axios({
                    url: getApiUrl({ path }),
                    params: criticalParams,
                });

                // Combine the results
                combinedData = {
                    count: mainResponse.data.count + criticalResponse.data.count,
                    results: [...mainResponse.data.results, ...criticalResponse.data.results],
                };
            }
            */

            commit('setStatus', { status: 'success' });
            commit('setData', { data: mainResponse.data });
            commit('setDataHash', { dataHash });

            dispatch('media/images/preload', { ids: getters.imageIds }, { root: true });
            await dispatch(
                'dataExport/setCSVData',
                { data: getters.formattedApiResults, prefix: 'hs' },
                { root: true },
            );

            return true;
        } catch (err) {
            errorHandler(err);
            commit('setStatus', { status: 'error' });
            return false;
        }
    },

    async exportData({ commit, getters, dispatch, rootState, rootGetters }, { perPage = null, page = null }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;
        const includeCritical = rootGetters['dataFilters/shouldIncludeCritical'];

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            perPage,
            page,
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/detections',
            startDate,
            endDate,
        });

        try {
            const mainResponse = await axios({
                url: getApiUrl({ path }),
                params,
            });

            let combinedData = mainResponse.data;

            if (includeCritical) {
                const criticalParams = {
                    ...params,
                    risk: 'critical',
                };

                const criticalResponse = await axios({
                    url: getApiUrl({ path }),
                    params: criticalParams,
                });

                combinedData = {
                    count: mainResponse.data.count + criticalResponse.data.count,
                    results: [...mainResponse.data.results, ...criticalResponse.data.results],
                };
            }

            commit('dataExport/setTotalItemCount', { data: combinedData.count || 0 }, { root: true });
            await dispatch('media/images/preload', { ids: getters.imageIds }, { root: true });
            await dispatch(
                'dataExport/setCSVData',
                { data: getters.formattedApiResults, prefix: 'hs' },
                { root: true },
            );
            commit('dataExport/setLoadingStatus', { status: false }, { root: true });

            return true;
        } catch (err) {
            errorHandler(err);
            return false;
        }
    },

    async exportDataUsingCursor({ rootState, rootGetters }, { nextCursor = null, limit = 10 }) {
        const apiPath = 'hsafety/detections/export';

        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            limit,
        });

        const path = buildStartEndDateUrl({
            path: apiPath,
            startDate,
            endDate,
        });

        try {
            const res = await axios({
                url: nextCursor || getApiUrl({ path }),
                params: !nextCursor ? params : {},
            });

            return res.data;
        } catch (err) {
            errorHandler(err);
            return null;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        ppe,
        zones,
    },
};
