import axios from 'axios';
import { get } from 'lodash';
import {
    buildStartEndDateUrl,
    buildUrlParams,
    createDataHash,
    getDefaultDataGetters,
    getDefaultDataMutations,
    getDefaultDataState,
} from '@/utils/store-helpers';
import { getApiUrl } from '@/utils/api';
import { errorHandler } from '@/utils/errors';
import { hsImagesTransformer } from '@/utils/transformers/hs/images';

const state = getDefaultDataState({ hasPagination: true });

const getters = {
    ...getDefaultDataGetters({ hasPagination: true }),

    imageIds(state, getters) {
        return getters.formattedApiResults
            .map((row) => get(row, 'id'))
            .filter((r) => r);
    },

    formattedApiResults(state, getters) {
        return hsImagesTransformer(getters.apiResults);
    },
};

const mutations = {
    ...getDefaultDataMutations({ hasPagination: true }),
};

const actions = {
    loadData({ state, commit, getters, dispatch, rootState, rootGetters }, { perPage = null, page = null }) {
        if (page) {
            commit('setPage', { page });
        }
        if (perPage) {
            commit('setPerPage', { perPage });
        }

        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            page: state.currentPage,
            perPage: state.perPage,
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/images',
            startDate,
            endDate,
        });

        const dataHash = createDataHash(path, params);
        if (state.dataHash === dataHash) {
            return Promise.resolve(true);
        }

        commit('setStatus', { status: 'loading' });
        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setStatus', { status: 'success' });
                commit('setData', { data: response.data });
                commit('setDataHash', { dataHash });
            })
            .then(() => dispatch('media/images/preload', { ids: getters.imageIds }, { root: true }))
            .then(() => dispatch(
                'dataExport/setCSVData',
                { data: getters.formattedApiResults, prefix: 'hs-image' },
                { root: true },
            ))
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },

    exportData(
        { commit, getters, dispatch, rootState, rootGetters },
        { perPage = null, page = null },
    ) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            perPage,
            page,
        });

        const path = buildStartEndDateUrl({
            path: 'hsafety/images',
            startDate,
            endDate,
        });

        return axios({
            url: getApiUrl({ path }),
            params,
        })
            .then((response) => {
                commit('setData', { data: response.data });
                commit('dataExport/setTotalItemCount', { data: response.data?.count || 0 }, { root: true });
                dispatch(
                    'dataExport/setCSVData',
                    { data: getters.formattedApiResults, prefix: 'hs-image' },
                    { root: true },
                );
            })
            .then(() => commit('dataExport/setLoadingStatus', { status: false }, { root: true }))
            .catch((err) => {
                errorHandler(err);
                commit('setStatus', { status: 'error' });
            });
    },

    async exportDataUsingCursor({ rootState, rootGetters }, { nextCursor = null, limit = 10 }) {
        const { dataFilters } = rootState;
        const { startDate, endDate } = dataFilters;

        const params = buildUrlParams({
            tags: rootGetters['dataFilters/getSelectedTagsSlugs'],
            plate: rootGetters['dataFilters/getSelectedPlates'],
            confidence: rootGetters['dataFilters/getConfidenceLevel'],
            risk: rootGetters['dataFilters/getRiskLevel'],
            limit,
        });

        const path = buildStartEndDateUrl({
            path: 'explorer/images/export',
            startDate,
            endDate,
        });

        try {
            const res = await axios({
                url: nextCursor || getApiUrl({ path }),
                params: !nextCursor ? params : {},
            });

            return res.data;
        } catch (err) {
            errorHandler(err);
            return null;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
