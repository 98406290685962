<template>
    <div class="columns is-multiline">
        <div class="column is-12">
            <b-field
                label="Name"
                :type="getFieldType('name')"
                :message="getFieldMessage('name')"
            >
                <b-input v-model.trim="cameraModel.name" />
            </b-field>
        </div>
        <div class="column is-12">
            <h3 class="title is-5 mb-3">Camera Configuration:</h3>
            <b-field
                label="Focal Length (mm)"
                :type="getFieldType('focal_length')"
                :message="getFieldMessage('focal_length')"
            >
                <b-input v-model.trim="cameraModel.focal_length" />
            </b-field>
            <b-field
                label="Vertical Field of View (degree)"
                :type="getFieldType('vertical_fov')"
                :message="getFieldMessage('vertical_fov')"
            >
                <b-input v-model.trim="cameraModel.vertical_fov" />
            </b-field>
            <b-field
                label="Horizontal Field of View (degree)"
                :type="getFieldType('horizontal_fov')"
                :message="getFieldMessage('horizontal_fov')"
            >
                <b-input v-model.trim="cameraModel.horizontal_fov" />
            </b-field>
        </div>
        <div class="column is-12">
            <div class="field">
                <p class="label">Plane Normal</p>
                <div class="columns is-multiline">
                    <div class="column is-4">
                        <b-field
                            label="X"
                            :type="getFieldType('plane_normal_x')"
                            :message="getFieldMessage('plane_normal_x')"
                        >
                            <b-input v-model.trim="cameraModel.plane_normal_x" type="number" step="0.01" />
                        </b-field>
                    </div>
                    <div class="column is-4">
                        <b-field
                            label="Y"
                            :type="getFieldType('plane_normal_y')"
                            :message="getFieldMessage('plane_normal_y')"
                        >
                            <b-input v-model.trim="cameraModel.plane_normal_y" type="number" step="0.01" />
                        </b-field>
                    </div>
                    <div class="column is-4">
                        <b-field
                            label="Z"
                            :type="getFieldType('plane_normal_z')"
                            :message="getFieldMessage('plane_normal_z')"
                        >
                            <b-input v-model.trim="cameraModel.plane_normal_z" type="number" step="0.01" />
                        </b-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-12">
            <div class="field">
                <p class="label">Plane Point</p>
                <div class="columns is-multiline">
                    <div class="column is-4">
                        <b-field
                            label="X"
                            :type="getFieldType('plane_point_x')"
                            :message="getFieldMessage('plane_point_x')"
                        >
                            <b-input v-model.trim="cameraModel.plane_point_x" type="number" step="0.01" />
                        </b-field>
                    </div>
                    <div class="column is-4">
                        <b-field
                            label="Y"
                            :type="getFieldType('plane_point_y')"
                            :message="getFieldMessage('plane_point_y')"
                        >
                            <b-input v-model.trim="cameraModel.plane_point_y" type="number" step="0.01" />
                        </b-field>
                    </div>
                    <div class="column is-4">
                        <b-field
                            label="Z"
                            :type="getFieldType('plane_point_z')"
                            :message="getFieldMessage('plane_point_z')"
                        >
                            <b-input v-model.trim="cameraModel.plane_point_z" type="number" step="0.01" />
                        </b-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-12">
            <h3 class="title is-5 mb-3">Site Person PPE Criteria:</h3>
            <b-field
                label=""
                :type="getFieldType('site_person_ppe_criteria')"
                :message="getFieldMessage('site_person_ppe_criteria')"
            >
                <b-checkbox
                    v-model="cameraModel.hsafety_setting.hivis_ppe_criteria"
                    native-value="hivis"
                    class="mr-5"
                >
                    <small>High Vis</small>
                </b-checkbox>
                <b-checkbox
                    v-model="cameraModel.hsafety_setting.hardhat_ppe_criteria"
                    native-value="hardhat"
                >
                    <small>Hard Hat</small>
                </b-checkbox>
            </b-field>
        </div>
        <div class="column is-12">
            <div class="field">
                <div class="label">Tags</div>
                <be-tags
                    prefix="CAM"
                    dropdown-position="top"
                    v-model="cameraTags"
                    :is-loading="isTagsLoading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { decimal, maxLength, required } from 'vuelidate/lib/validators';
import { isArray, isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import { SITE_CAMERA_NAME_MAXLENGTH } from '@/utils/sites/constants';
import BeTags from '@/components/global/BeTags';
import {
    CAMERA_VALIDATION_MESSAGES,
    getHSCameraSaveData,
    getDefaultCameraTagsState,
    getDefaultHSCameraState,
} from '@/utils/sites/cameras';
import noticesMixin from '@/mixins/noticesMixin';
import manageHSCameraMixin from '@/mixins/hs/manageHSCameraMixin';

export default {
    mixins: [validationMixin, noticesMixin, manageHSCameraMixin],

    validations: {
        cameraModel: {
            name: {
                required,
                isUnique(value) {
                    const trimmed = `${value}`.toLowerCase().trim();
                    if (!trimmed) {
                        return true;
                    }
                    return this.camerasNames.indexOf(trimmed) === -1;
                },
                maxLength: maxLength(SITE_CAMERA_NAME_MAXLENGTH),
            },
            hsafety_setting: {
                heavy_plant_to_public_safety_distance: {
                    numeric: (value, nestedModel) => (nestedModel.heavy_plant_to_public_safety_distance_enabled
                        ? decimal(value) && value > 0
                        : true),
                },
                heavy_plant_to_worker_safety_distance: {
                    numeric: (value, nestedModel) => (nestedModel.heavy_plant_to_worker_safety_distance_enabled
                        ? decimal(value) && value > 0
                        : true),
                },
                temporary_speed_limit: {
                    numeric: (value) => isEmpty(value) || (decimal(value) && value >= 0),
                },
            },
            focal_length: {
                required,
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            vertical_fov: {
                required,
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            horizontal_fov: {
                required,
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            plane_normal_x: {
                numeric: (value) => isEmpty(value) || decimal(value),
            },
            plane_normal_y: {
                numeric: (value) => isEmpty(value) || decimal(value),
            },
            plane_normal_z: {
                numeric: (value) => isEmpty(value) || decimal(value),
            },
            plane_point_x: {
                numeric: (value) => isEmpty(value) || decimal(value),
            },
            plane_point_y: {
                numeric: (value) => isEmpty(value) || decimal(value),
            },
            plane_point_z: {
                numeric: (value) => isEmpty(value) || decimal(value),
            },
        },
    },

    components: {
        BeTags,
    },

    data() {
        return {
            cameraModel: {},
            cameraTags: [],
            isTagsLoading: false,
            validationMessages: {
                ...CAMERA_VALIDATION_MESSAGES,
                name: {
                    required: CAMERA_VALIDATION_MESSAGES.name,
                    isUnique:
                        'You already have a camera with the same name. Please choose another name for this camera.',
                    maxLength: `Site name must be less than ${SITE_CAMERA_NAME_MAXLENGTH}
                    characters in length. Please choose a shorter name.`,
                },
                hsafety_setting: {
                    temporary_speed_limit: 'Please enter a valid speed limit',
                    heavy_plant_to_public_safety_distance:
                        'Please enter a valid distance',
                    heavy_plant_to_worker_safety_distance:
                        'Please enter a valid distance',
                },
                plane_normal_x: {
                    numeric: CAMERA_VALIDATION_MESSAGES.plane_normal_x.numeric,
                },
                plane_normal_y: {
                    numeric: CAMERA_VALIDATION_MESSAGES.plane_normal_y.numeric,
                },
                plane_normal_z: {
                    numeric: CAMERA_VALIDATION_MESSAGES.plane_normal_z.numeric,
                },
            },
        };
    },

    computed: {
        camerasNames() {
            if (!isArray(this.cameras)) {
                return [];
            }
            return this.cameras
                .filter((camera) => camera.id !== this.cameraId)
                .map((camera) => camera.name.toLowerCase().trim());
        },
    },

    watch: {
        cameraId: {
            handler() {
                this.cameraModel = getDefaultHSCameraState(this.camera);
                this.cameraTags = getDefaultCameraTagsState(this.camera);
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('sites', [
            'createSiteCamera',
            'updateSiteCamera',
            'updateCameraTags',
        ]),

        save() {
            if (this.isLoading) {
                return Promise.resolve(false);
            }

            this.$v.$touch();
            if (this.$v.$invalid) {
                return Promise.resolve(false);
            }

            this.isLoading = true;
            return this[this.isNew ? 'create' : 'update']()
                .then((result) => {
                    this.displaySuccessNotice({
                        message: this.isNew
                            ? 'Site camera added successfully'
                            : 'Site camera updated successfully',
                    });
                    return result;
                })
                .catch((error) => {
                    this.$emit('request-error', error.message);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        update(updateTags = true) {
            return this.updateSiteCamera({
                cameraId: this.cameraId,
                siteId: this.site.id,
                data: getHSCameraSaveData(this.cameraModel),
            })
                .then(() => (updateTags ? this.updateTags({}) : true))
                .then(() => this.camera);
        },

        create() {
            return this.createSiteCamera({
                siteId: this.site.id,
                data: getHSCameraSaveData(this.cameraModel),
            }).then(({ camera }) => {
                this.updateTags({ camera });
                return camera;
            });
        },

        updateTags({ camera = null }) {
            let tags = [...this.cameraTags];

            if (camera && camera.tags && camera.tags.length) {
                tags = tags.concat(camera.tags);
            }
            return this.updateCameraTags({
                siteId: this.site.id,
                cameraId: camera ? camera.id : this.cameraId,
                tags,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.camera-inline-field {
    .control {
        display: inline-flex;
        align-items: center;
    }

    &__input {
        &.is-small {
            width: 90px;

            ::v-deep input {
                text-align: center;
            }
        }
    }
}
</style>
