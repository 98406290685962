<template>
    <b-tooltip label="Tooltip top">
        <v-group v-if="configs.length" ref="group">
            <v-line
                v-for="(config, index) in configs"
                :key="`config-${index}`"
                :config="config"
                @mousemove="handleMouseOver(true)"
                @mouseout="handleMouseOver(false)"
                @focusin="handleMouseOver(true)"
                @focusout="handleMouseOver(false)"
            />
        </v-group>
    </b-tooltip>
</template>

<script>
import { flatten, get } from 'lodash';
import { convertToCanvasRect } from '@/utils/canvas';
import { COLORS, ROI_TYPES } from '@/utils/constants';
import { getRoiColor } from '@/utils/roi';

export default {
    props: {
        roi: {
            type: Object,
            required: true,
        },
        scale: {
            type: Number,
            required: true,
        },
        translate: {
            type: Array,
            default: () => [0, 0],
        },
        showTooltip: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            strokeWidth: 2,
            strokeHalfWidth: 1,
        };
    },

    computed: {
        roiValues() {
            return get(this.roi, 'roi', []);
        },
        configs() {
            const roiType = ROI_TYPES[this.roi.roi_type];
            const roiColor = getRoiColor(roiType);

            const configs = this.roiValues.map((roi) => {
                const points = flatten(roi).map((point) => parseInt(parseInt(point, 10) / this.scale, 10));
                const strokeColor = roiColor || COLORS.lightblue;

                return {
                    points,
                    stroke: strokeColor,
                    strokeWidth: this.strokeWidth,
                    closed: true,
                    dash: [25, 5],
                };
            });
            return configs;
        },

        bb() {
            const bb = this.convertBB(this.result.bb);
            return {
                x: bb[0],
                y: bb[1],
                width: bb[2],
                height: bb[3],
            };
        },
    },

    methods: {
        convertBB(bb) {
            return convertToCanvasRect({
                str: [bb.l, bb.t, bb.r, bb.b],
                scale: this.scale,
                translate: this.translate,
            });
        },

        handleMouseOver(showText = false) {
            this.showTooltip(showText, this?.roi?.tags[0]?.label || null);
        },
    },
};
</script>
