<template>
    <mgl-marker
        :coordinates="coordinates"
        :draggable="false"
    >
        <div slot="marker">
            <div class="map-marker" :class="{ 'has-popup-opened': false, 'has-popup': hasPopup }">
                <div ref="icon" class="map-marker__icon" v-bind:style="markerSize">
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                        <defs>
                            <radialGradient :id="`radial-fill-${item.metadata.id}`">
                                <stop v-if="displayHigh" offset="0%" stop-color="#dc2626CC" />
                                <!-- <stop v-if="item.medium" offset="30%" stop-color="#1767597F" /> -->
                                <stop v-if="displayHigh && displayMedium" v-bind:offset="highPercentage" stop-color="#dc2626CC" />
                                <stop v-if="displayHigh && displayMedium" v-bind:offset="highPercentageFade" stop-color="#ca8a04CC" />
                                <stop v-if="displayMedium" offset="100%" stop-color="#ca8a04CC" />
                            </radialGradient>
                        </defs>
                        <circle cx="32" cy="32" r="24" :fill="`url(#radial-fill-${item.metadata.id})`" />
                    </svg>
                </div>
            </div>
        </div>

        <mgl-popup
            v-if="hasPopup"
            :showed="isPopupOpened"
            class="site-popup"
            ref="popup"
            :close-button="false"
            @open="popupOpen"
            @close="popupClose"
            :offset="[0, 0]"
        >
            <div class="site-marker">
                <div class="site-marker__inner">
                    <div class="label-large" v-text="item.metadata.name" />
                    <div class="site-marker__row">
                        <div class="site-marker__row_icon">
                            <div class="site-marker__icon is-danger">
                                <b-icon :icon="`blank`" />
                            </div>
                            <div class="label-sub-text">Elevated</div>
                        </div>
                        <div class="label-small" v-text="item.high" />
                    </div>
                    <div class="site-marker__row">
                        <div class="site-marker__row_icon">
                            <div class="site-marker__icon is-warning">
                                <b-icon :icon="`blank`" />
                            </div>
                            <div class="label-sub-text">Moderate</div>
                        </div>
                        <div class="label-small" v-text="item.medium" />
                    </div>
                    <div class="site-marker__row">
                        <div class="label-sub-text">Percentage</div>
                        <div class="label-small" v-text="highPercentage" />
                    </div>
                </div>
            </div>
        </mgl-popup>
    </mgl-marker>
</template>

<script>
import { MglMarker, MglPopup } from 'v-mapbox';

const MIN_SIZE = 64;
const MAX_SIZE = 128;

export default {
    components: {
        MglMarker,
        MglPopup,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
        // 100% is MAX_SIZE, 0% is MIN_SIZE
        sizePercentage: {
            type: Number,
            required: true,
        },
        marker: {
            type: Object,
            default: () => ({}),
        },
        displayHigh: {
            type: Boolean,
            default: true,
        },
        displayMedium: {
            type: Boolean,
            default: true,
        },
        hasPopup: {
            type: Boolean,
            default: true,
        },
        hasLabel: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isPopupOpened: false,
            displayOnRight: false,
        };
    },

    computed: {
        coordinates() {
            return [
                this.item.metadata.lng,
                this.item.metadata.lat,
            ];
        },

        markerSize() {
            const size = (MAX_SIZE - MIN_SIZE) * this.sizePercentage + MIN_SIZE;
            return {
                width: `${size}px`,
                height: `${size}px`,
            };
        },
        highPercentage() {
            return `${((this.item.high / (this.item.high + this.item.medium)) * 100).toFixed(2)}%`;
        },
        highPercentageFade() {
            return `${((this.item.high / (this.item.high + this.item.medium)) * 100 + 10).toFixed(2)}%`;
        },
    },

    methods: {
        popupOpen() {
            this.isPopupOpened = true;
        },
        popupClose() {
            this.isPopupOpened = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.site-marker {
    font-family: $family-primary;
    width: 100%;

    @include mobile {
        width: 260px;
    }

    &__inner {
        padding: 10px;
        @include mobile {
            padding: 5px;
        }
    }

    &__row_icon {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    &__row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    &__footer {
        @include tablet {
            display: flex;
            align-items: stretch;
        }

        &__cta {
            @include tablet {
                flex: 1;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    &__icon {
        position: relative;
        margin: 10px 0;
        width: 25px;
        height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-rounded;

        &.is-success {
            background: $low;
            color: $low-invert;
        }

        &.is-warning {
            background: $moderate;
            color: $moderate-invert;
        }

        &.is-danger {
            background: $critical;
            color: $critical-invert;
        }

        &.is-critical {
            background: $critical;
            color: $critical-invert;
        }

        &.is-info {
            background: $info;
            color: $info-invert;
        }

        &.is-light {
            background: $light;
            color: $light-invert;
        }
    }
}

.map-marker {
    display: block;
    position: relative;

    &__info,
    &__icon-bg {
        transition: all $speed $easing;
    }

    &__icon {
        display: block;
        width: 64px;
        height: 64px;

        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &.has-popup-opened &__info {
        opacity: 0;
        pointer-events: none;
    }

    &__info {
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 5px;
        text-align: left;
        background-color: $grey-darker;
        color: $white;
        padding: 12px 16px;
        border-radius: $radius;

        &.is-right {
            left: auto;
            right: 100%;
            margin-left: 0;
            margin-right: 5px;
        }
    }

    &__text {

        font-weight: $weight-normal;
        font-size: 16px;
        min-width: 200px;
        letter-spacing: 0.01em;
        line-height: 1;
        font-family: $family-primary;
        line-height: 1;
    }

    cursor: default;

    &.has-popup {
        cursor: pointer;
    }

    &.has-popup:hover &,
    &.has-popup-opened & {
        &__icon-bg {
            fill: $grey-darker;
        }

        &__text {
            background: $grey-darker;
        }
    }
}
</style>
