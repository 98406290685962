<template>
    <div>
        <div class="be-tags" :class="{ 'is-loading': isLoading || isAllLoading }">
            <div class="be-tags__header">
                <b-field class="be-tags__field" v-if="!readonly">
                    <b-autocomplete
                        v-model.trim="current"
                        field="label"
                        :placeholder="isSearchable ? 'Search Tags' : 'Add tag'"
                        :data="tags"
                        :clearable="false"
                        :rounded="true"
                        :loading="isFetching"
                        :clear-on-select="true"
                        :expanded="true"
                        :dropdown-position="dropdownPosition"
                        :open-on-focus="isSearchable && false"
                        @select="select"
                        @typing="onType"
                        @keydown.native.enter="add()"
                        :icon-right="isSearchable ? 'crop-free' : ''"
                        :icon-right-clickable="isSearchable"
                        @icon-right-click="onIconClick"
                    >
                        <template v-slot:empty>
                            No results found
                        </template>
                    </b-autocomplete>
                    <p class="control" v-if="displayButton">
                        <b-button
                            :disabled="!current"
                            class="be-button is-wider is-primary"
                            @click="add()"
                            :loading="isAdding"
                        >
                            {{ buttonLabel }}
                        </b-button>
                    </p>
                </b-field>
                <div class="filter-search__extra" v-if="computedValue.length && onClear">
                    <div class="filter-search__extra__control field">
                        <button type="button" class="be-button-link is-grey-lighter" @click="handleClear">Clear</button>
                    </div>
                </div>
            </div>

            <div class="be-tags__selected" v-if="computedValue.length">
                <b-field grouped group-multiline>
                    <div
                        v-for="(tag, index) in computedValue"
                        :key="`tag${index}`"
                        class="be-tags__selected__tag control"
                    >
                        <b-tag
                            attached
                            aria-label="Remove tag"
                            :closable="isTagRemovable(tag)"
                            @close="removeTag(tag, index)"
                            :class="{ 'is-readonly': readonly }"
                        >
                            <span class="has-cursor-default">
                                {{ tag.label }}
                            </span>
                        </b-tag>
                    </div>
                </b-field>
            </div>
        </div>
        <be-tags-popup
            v-if="isSearchable"
            :active.sync="isModalOpen"
            :current-tags="computedValue"
            @select-tags="onTagsSelect"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { slugify } from '@/utils/str';
import { getApiUrl } from '@/utils/api';
import noticesMixin from '@/mixins/noticesMixin';
import BeTagsPopup from '@/components/global/BeTagsPopup';

export default {
    name: 'BeTags',
    components: { BeTagsPopup },
    mixins: [noticesMixin],

    props: {
        onClear: {
            type: Function,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'tag',
            validator: (val) => ['tag', 'search'].includes(val),
        },
        value: {
            type: Array,
            default: () => [],
        },
        displayButton: {
            type: Boolean,
            default: true,
        },
        buttonLabel: {
            type: String,
            default: 'Add',
        },
        dropdownPosition: {
            type: String,
            default: 'auto',
            validator: (val) => ['top', 'bottom', 'auto'].includes(val),
        },
        prefix: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            tags: [],
            current: null,
            newValue: this.value,
            isFetching: false,
            isAdding: false,
            isModalOpen: false,
        };
    },

    computed: {
        ...mapGetters({
            isAllLoading: 'tags/isLoading',
        }),

        isTaggable() {
            return this.type === 'tag';
        },

        isSearchable() {
            return this.type === 'search';
        },

        computedValue: {
            get() {
                return this.newValue;
            },
            set(value) {
                this.newValue = value;
                this.$emit('input', value);
            },
        },
    },

    watch: {
        value(value) {
            this.newValue = value;
        },
        current(val) {
            if (!val) {
                this.tags = [...this.$store.state.tags.tags];
            }
        },
    },

    mounted() {
        this.tags = [];
        if (this.isSearchable) {
            this.loadTags()
                .then(() => {
                    this.tags = [...this.$store.state.tags.tags];
                });
        }
    },

    methods: {
        ...mapActions('tags', ['loadTags']),

        handleClear() {
            if (this.onClear) {
                this.onClear();
            }
        },

        isTagRemovable(tag) {
            if (this.isSearchable) {
                return !this.readonly;
            }
            return !this.readonly && tag.editable;
        },

        onType: debounce(function (filter) {
            if (this.isSearchable) {
                this.filterTagsOnType(filter);
            } else {
                this.loadTagsOnType(filter);
            }
        }, 200),

        filterTagsOnType(filter) {
            if (!filter.length) {
                this.tags = [...this.$store.state.tags.tags];
            }

            const ff = `${filter}`.trim().toLowerCase();

            const currentSlugs = this.computedValue.map((t) => t.slug);

            this.tags = this.$store.state.tags.tags.filter(
                (tag) => {
                    if (currentSlugs.includes(tag.slug)) {
                        return false;
                    }
                    return tag.label.toLowerCase().includes(ff) || tag.slug.toLowerCase().includes(ff);
                },
            );
        },

        loadTagsOnType(filter) {
            if (!filter.length) {
                this.tags = [];
                return;
            }

            this.isFetching = true;

            const url = getApiUrl({ path: 'tags', query: { filter } });
            this.$http.get(url)
                .then(({ data }) => {
                    this.tags = [...data].filter((tag) => {
                        if (this.isSearchable) {
                            return true;
                        }
                        return tag && tag.editable;
                    });
                })
                .catch((error) => {
                    this.tags = [];
                    throw error;
                })
                .finally(() => {
                    this.isFetching = false;
                });
        },

        add() {
            if (this.isLoading || !this.current) {
                return;
            }

            const current = {
                slug: slugify(this.current),
                label: `${this.prefix}: ${this.current}`,
                editable: true,
            };

            this.maybeAddTag(current)
                .then(() => {
                    this.select(current);
                    this.current = null;
                });
        },

        select(option) {
            if (this.isLoading || !option) {
                return;
            }

            const tagExists = this.computedValue.reduce((exists, tag) => (exists || tag.slug === option.slug), false);

            if (!tagExists) {
                this.computedValue = [...this.computedValue, option];
            }
        },

        removeTag(tag) {
            if (this.isLoading) {
                return;
            }
            this.computedValue = this.computedValue.filter((selected) => selected.label !== tag.label);
        },

        maybeAddTag(addTag) {
            if (!this.isTaggable) {
                return Promise.resolve(true);
            }

            const tagExists = this.tags.reduce((exists, tag) => (exists || tag.slug === addTag.slug), false);

            if (tagExists) {
                return Promise.resolve(true);
            }

            this.isAdding = true;
            return this.$http({
                url: getApiUrl({ path: 'tags' }),
                data: addTag,
                method: 'POST',
            })
                .catch((error) => {
                    this.displayErrorNotice({ message: 'Cannot add tag' });
                    throw error;
                })
                .finally(() => {
                    this.isAdding = false;
                });
        },

        onIconClick() {
            if (!this.isSearchable) {
                return;
            }
            this.isModalOpen = true;
        },

        onTagsSelect(tags) {
            this.computedValue = tags;
        },
    },
};
</script>

<style lang="scss" scoped>
.be-tags {
    &.is-loading {
        opacity: .8;
        cursor: wait;
        position: relative;

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: "";
            @include z-index(above);
        }
    }

    &__field {
        margin-bottom: 0;
        width: 100%;
        max-width: 400px;

        ::v-deep .field.has-addons .control:not(:last-child) {
            margin-right: 0;
        }

        ::v-deep .control .input {
            border-right: 0;
        }
    }

    &__selected {
        &__tag {
            background: $grey;
            font-weight: $weight-normal;

            ::v-deep span.tag {
                min-width: 90px;
                height: 40px;
                color: $text;
                font-size: 14px;
                letter-spacing: 0.01em;
                background: $grey;
                justify-content: center;

                &.is-readonly {
                    min-width: 110px;
                }
            }

            ::v-deep a.tag {
                background: transparent;
                margin-right: 10px;
                color: $grey-light;
                transition: color $speed $easing;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
    }
}

::v-deep .tags .tag {
    margin-bottom: 0 !important;
}
</style>
