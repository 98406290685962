<template>
    <div class="columns is-multiline">
        <div class="column is-12">
            <b-field
                label="Name"
                :type="getFieldType('name')"
                :message="getFieldMessage('name')"
            >
                <b-input v-model.trim="computedValue.name" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="Focal Length (mm)"
                :type="getFieldType('focal_length')"
                :message="getFieldMessage('focal_length')"
            >
                <b-input v-model.trim="computedValue.focal_length" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="Vertical Field of View (degree)"
                :type="getFieldType('vertical_fov')"
                :message="getFieldMessage('vertical_fov')"
            >
                <b-input v-model.trim="computedValue.vertical_fov" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="Horizontal Field of View (degree)"
                :type="getFieldType('horizontal_fov')"
                :message="getFieldMessage('horizontal_fov')"
            >
                <b-input v-model.trim="computedValue.horizontal_fov" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="X-axis (Plane Normal)"
                :type="getFieldType('plane_normal_x')"
                :message="getFieldMessage('plane_normal_x')"
            >
                <b-input v-model.trim="computedValue.plane_normal_x" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="Y-axis (Plane Normal)"
                :type="getFieldType('plane_normal_y')"
                :message="getFieldMessage('plane_normal_y')"
            >
                <b-input v-model.trim="computedValue.plane_normal_y" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="Z-axis (Plane Normal)"
                :type="getFieldType('plane_normal_z')"
                :message="getFieldMessage('plane_normal_z')"
            >
                <b-input v-model.trim="computedValue.plane_normal_z" />
            </b-field>
        </div>
        <div class="column is-12">
            <b-field
                label="Expected Event Frequency"
                :type="getFieldType('event_frequency')"
                :message="getFieldMessage('event_frequency')"
            >
                <b-select v-model="computedValue.event_frequency" expanded>
                    <option
                        v-for="(frequency, index) in frequencyList"
                        :key="`frequency${index}`"
                        :value="frequency.id"
                    >
                        {{ frequency.description }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <div class="column is-12">
            <div class="field">
                <div class="label">Tags</div>
                <be-tags
                    prefix="CAM"
                    dropdown-position="top"
                    v-model="cameraTags"
                    :is-loading="isTagsLoading"
                />
            </div>
        </div>
        <div class="column is-12">
            <b-field
                label="Country"
                :type="getFieldType('country')"
                :message="getFieldMessage('country')"
            >
                <b-select v-model="computedValue.country" expanded>
                    <option
                        v-for="country in countries"
                        :key="`country${country.slug}`"
                        :value="country.slug"
                    >
                        {{ country.label }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <div v-if="endpoint" class="column is-12">
            <b-field label="Unique HTTPS Post URL">
                <be-copy :copy-text="endpoint" />
            </b-field>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { decimal, maxLength, required } from 'vuelidate/lib/validators';
import { find, get, isArray, isEmpty } from 'lodash';
import BeCopy from '@/components/global/BeCopy';
import BeTags from '@/components/global/BeTags';
import modelMixin from '@/mixins/modelMixin';
import manageCameraStepMixin from '@/mixins/manageCameraStepMixin';
import { SITE_CAMERA_NAME_MAXLENGTH } from '@/utils/sites/constants';

export default {
    props: {
        endpoint: {
            type: String,
            default: '',
        },
        site: {
            type: Object,
            required: true,
        },
        cameraId: {
            type: String,
            default: '',
        },
        tags: {
            type: Array,
            default: () => [],
        },
        frequencyList: {
            type: Array,
            default: () => [],
        },
        isTagsLoading: Boolean,
    },
    mixins: [
        modelMixin,
        manageCameraStepMixin,
        validationMixin,
    ],

    validations: {
        computedValue: {
            name: {
                required,
                isUnique(value) {
                    const trimmed = `${value}`.toLowerCase().trim();
                    return trimmed && this.camerasNames.indexOf(trimmed) === -1;
                },
                maxLength: maxLength(SITE_CAMERA_NAME_MAXLENGTH),
            },
            event_frequency: {
                required,
            },
            country: {
                required,
            },
            focal_length: {
                required,
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            vertical_fov: {
                required,
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            horizontal_fov: {
                required,
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            plane_normal_x: {
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            plane_normal_y: {
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
            plane_normal_z: {
                numeric: (value) => isEmpty(value) || (decimal(value) && value > 0),
            },
        },
    },

    components: {
        BeCopy,
        BeTags,
    },

    data() {
        return {
            cameraTags: [...this.tags],
        };
    },

    computed: {
        cameras() {
            return get(
                find(this.$store.state.sites.sitesCameras, { id: this.site.id }),
                'cameras',
                [],
            );
        },
        camerasNames() {
            if (!isArray(this.cameras)) {
                return [];
            }
            return this.cameras
                .filter((camera) => (camera.id !== this.cameraId))
                .map((camera) => camera.name.toLowerCase().trim());
        },
    },

    watch: {
        cameraTags: {
            handler(val) {
                this.$emit('update-tags', val);
            },
            deep: true,
        },
    },
};
</script>
