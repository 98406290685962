<template>
    <manage-popup
        v-if="active"
        class="manage-popup"
        width="wider"
        :active="active"
        :is-loading="isLoading"
        @close="maybeClose"
    >
        <template v-slot:title>
            {{ isEdit ? 'Edit Camera' : 'Setup Camera' }}
            <small>(Step {{ step }} / 3)</small>
        </template>
        <template v-slot:body>
            <be-request-error-message v-if="requestErrorMessage" @close="requestErrorMessage = null">
                {{ requestErrorMessage }}
            </be-request-error-message>

            <component
                ref="step"
                :is="stepComponent"
                :key="`step${step}`"
                :camera-id="internalCameraId"
                :site="site"
                @loading="isLoading = true"
                @ready="isLoading = false"
                @request-error="requestErrorMessage = $event"
            />
        </template>
        <template v-slot:footer>
            <button
                v-if="isEdit"
                type="button"
                class="be-button-link is-danger"
                @click.prevent="remove"
                :disabled="isLoading"
                :loading="isRemoving"
            >
                Remove Camera
            </button>
            <div class="footer-buttons">
                <b-button
                    v-if="step > 1"
                    class="be-button is-wider is-secondary"
                    :loading="isLoading"
                    @click.prevent="prev()"
                >
                    Back
                </b-button>
                <b-button
                    v-if="step < 3"
                    class="be-button is-wider"
                    type="is-primary"
                    :loading="isLoading"
                    @click.prevent="forward()"
                >
                    Continue
                </b-button>
                <b-button
                    v-if="step === 3"
                    class="be-button is-wider"
                    type="is-success"
                    :loading="isLoading"
                    @click.prevent="forward()"
                >
                    Enable Camera
                </b-button>
            </div>
        </template>
    </manage-popup>
</template>

<script>
import { mapActions } from 'vuex';
import BeRequestErrorMessage from '@/components/global/BeRequestErrorMessage';
import noticesMixin from '@/mixins/noticesMixin';
import ManagePopup from '@/components/local/sites/ManagePopup';
import ManageHSCameraPopupStep1 from '@/components/local/sites/hs/partials/ManageHSCameraPopupStep1';
import ManageHSCameraPopupStep2 from '@/components/local/sites/hs/partials/ManageHSCameraPopupStep2';
import ManageHSCameraPopupStep3 from '@/components/local/sites/hs/partials/ManageHSCameraPopupStep3';
import ManageHSCameraPopupStep4 from '@/components/local/sites/hs/partials/ManageHSCameraPopupStep4';

export default {
    components: {
        ManagePopup,
        ManageHSCameraPopupStep1,
        ManageHSCameraPopupStep2,
        ManageHSCameraPopupStep3,
        ManageHSCameraPopupStep4,
        BeRequestErrorMessage,
    },

    mixins: [
        noticesMixin,
    ],

    props: {
        active: {
            type: Boolean,
            required: true,
        },
        cameraId: {
            type: String,
            default: '',
        },
        site: {
            type: Object,
            required: true,
            // default: null,
        },
    },

    data() {
        return {
            internalCameraId: this.cameraId,
            step: 1,
            isLoading: false,
            isRemoving: false,
            requestErrorMessage: null,
            closeConfirmationMessage: 'Are you sure you want to exit camera creation without saving?',
        };
    },

    computed: {
        isEdit() {
            return !!this.internalCameraId;
        },

        stepComponent() {
            switch (this.step) {
                case 1:
                    return 'manage-h-s-camera-popup-step1';
                case 2:
                    return 'manage-h-s-camera-popup-step2';
                case 3:
                    return 'manage-h-s-camera-popup-step3';
                default:
                    return null;
            }
        },
    },

    mounted() {
        // ensure we mount on the first step
        this.step = 1;
    },

    methods: {
        ...mapActions('sites', ['removeSiteCamera']),

        forward() {
            if (!this.$refs.step) {
                return;
            }
            if (this.step === 1) {
                this.$refs.step.save()
                    .then((camera) => {
                        if (camera) {
                            this.internalCameraId = camera.id;
                            this.requestErrorMessage = null;
                            this.next();
                        }
                    });
                return;
            }

            if (this.step === 2) {
                this.next();
                return;
            }

            if (this.step === 3) {
                this.$refs.step.save()
                    .then((result) => {
                        if (result) {
                            this.close();
                        }
                    });
            }
        },

        next() {
            this.step += 1;
        },

        prev() {
            this.step -= 1;
        },

        remove() {
            if (this.isLoading) {
                return;
            }
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this camera?',
                type: 'is-danger',
                onConfirm: () => {
                    this.isRemoving = true;
                    this.removeSiteCamera({
                        siteId: this.site.id,
                        cameraId: this.internalCameraId,
                    })
                        .then(() => {
                            this.displaySuccessNotice({
                                message: 'Site camera removed successfully',
                            });
                            this.close();
                        })
                        .catch(() => {
                            this.isRemoving = false;
                        });
                },
            });
        },

        maybeClose() {
            if (this.isLoading) {
                return;
            }

            this.$buefy.dialog.confirm({
                message: this.closeConfirmationMessage,
                onConfirm: () => this.close(),
            });
        },

        close() {
            this.requestErrorMessage = null;
            this.step = 1;
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-popup {
    ::v-deep .modal-card-foot {
        justify-content: space-between;
    }

    .footer-buttons {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
