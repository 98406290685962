import { get, isPlainObject, set } from 'lodash';
import { CAMERA_APP_TYPES, CAMERA_COUNTRIES, CAMERA_LOCATIONS } from '@/utils/sites/constants';

export const HS_CAMERA_VIOLATIONS_TYPES = [
    {
        type: 0,
        handle: 'ExclusionZone',
        label: 'People Exclusion Zones',
    },
    {
        type: 1,
        handle: 'ExclusionZone-Other',
        label: 'Things in Exclusion Zones',
    },
    {
        type: 2,
        handle: 'SafetyZone-Public',
        label: 'People (without PPE) in Safety Zones',
    },
    {
        type: 3,
        handle: 'SafetyZone-PPE',
        label: 'People (with PPE) in Safety Zones',
    },
    {
        type: 4,
        handle: 'SafetyZone-Other',
        label: 'Things in Safety Zones',
    },
    {
        type: 5,
        handle: 'OverSpeed-GroupA',
        label: 'Vehicles Overspeed by 10-20kmph',
    },
    {
        type: 6,
        handle: 'OverSpeed-GroupB',
        label: 'Vehicles Overspeed by 20-30kmph',
    },
    {
        type: 7,
        handle: 'OverSpeed-GroupC',
        label: 'Vehicles Overspeed by 30kmph or more',
    },
    {
        type: 8,
        handle: 'DistanceZone-Public',
        label: 'People near plant (No PPE)',
    },
    {
        type: 9,
        handle: 'DistanceZone-Worker',
        label: 'People near plant (With PPE)',
    },
    {
        type: 10,
        handle: 'Worker-Down',
        label: 'Worker Down',
    },
    {
        type: 11,
        handle: 'Volatile-Vehicle',
        label: 'Volatile Vehicle',
    },
];

export const VEHICLE_RISK_METRICS = [
    {
        type: 0,
        handle: 'PeakOccupancy',
        label: 'Peak Occupancy',
    },
    {
        type: 1,
        handle: 'PeakVacancy',
        label: 'Peak Vacancy',
    },
    {
        type: 2,
        handle: 'PeakDwellTime',
        label: 'Length of Stay',
    },
    {
        type: 3,
        handle: 'NumberOfEntries',
        label: 'No. of Entries',
    },
    {
        type: 4,
        handle: 'NumberOfExits',
        label: 'No. of Exits',
    },
    {
        type: 5,
        handle: 'AverageOccupancy',
        label: 'Average Occupancy',
    },
    {
        type: 6,
        handle: 'AverageVacancy',
        label: 'Average Vacancy',
    },
    {
        type: 7,
        handle: 'AverageDwellTime',
        label: 'Average Length of Stay',
    },
];

export const HS_CAMERA_THRESHOLD_PERIODS = [
    {
        id: 1,
        label: 'Off',
    },
    {
        id: 2,
        label: 'Hour',
    },
    {
        id: 3,
        label: 'Day',
    },
];

export const CAMERA_VALIDATION_MESSAGES = {
    name: 'Camera name is required',
    event_frequency: 'Event frequency is required',
    country: 'Country is required',
    focal_length: {
        required: 'Focal length is required',
        numeric: 'Focal length must be a number',
    },
    vertical_fov: {
        required: 'Vertical field of view is required',
        numeric: 'Vertical field of view must be a number',
    },
    horizontal_fov: {
        required: 'Horizontal field of view is required',
        numeric: 'Horizontal field of view must be a number',
    },
    plane_normal_x: {
        numeric: 'X value must be a number',
    },
    plane_normal_y: {
        numeric: 'Y value must be a number',
    },
    plane_normal_z: {
        numeric: 'Z value must be a number',
    },
    plane_point_x: {
        numeric: 'X value must be a number',
    },
    plane_point_y: {
        numeric: 'Y value must be a number',
    },
    plane_point_z: {
        numeric: 'Z value must be a number',
    },
};

export const getDefaultCameraState = (item) => ({
    name: item ? item.name : '',
    event_frequency: get(item, 'event_frequency.id', null),
    tracking: true, // always TRUE
    face_redaction: get(item, 'face_redaction', true),
    function_type: get(item, 'function_type', 0),
    country: get(item, 'country', CAMERA_COUNTRIES[0].slug),
    focal_length: get(item, 'focal_length', 2.8),
    vertical_fov: get(item, 'vertical_fov', 72),
    horizontal_fov: get(item, 'horizontal_fov', 130),
    plane_normal_x: get(item, 'plane_normal_x', null),
    plane_normal_y: get(item, 'plane_normal_y', null),
    plane_normal_z: get(item, 'plane_normal_z', null),
});

export const getDefaultHSCameraState = (item) => {
    const originalMetrics = get(item, 'violation_metrics', []).reduce((curr, metric) => {
        // eslint-disable-next-line no-param-reassign
        curr[metric.violation_type] = metric;
        return curr;
    }, {});

    const getDefaultMetricsMeasurements = (type) => ({
        medium_risk_threshold: get(originalMetrics, `${type}.medium_risk_threshold`, 0),
        high_risk_threshold: get(originalMetrics, `${type}.high_risk_threshold`, 0),
        alert_threshold: get(originalMetrics, `${type}.alert_threshold`, 0),
        violation_type: type,
        threshold_period: get(originalMetrics, `${type}.threshold_period.id`, HS_CAMERA_THRESHOLD_PERIODS[0].id),
    });

    const metrics = HS_CAMERA_VIOLATIONS_TYPES.reduce((curr, type) => {
        // eslint-disable-next-line no-param-reassign
        curr[type.type] = getDefaultMetricsMeasurements(type.type);
        return curr;
    }, {});

    return {
        name: get(item, 'name', ''),
        event_frequency: get(item, 'event_frequency.id', null),
        tracking: true, // always TRUE
        face_redaction: get(item, 'face_redaction', true),
        function_type: get(item, 'function_type', 0),
        country: get(item, 'country', CAMERA_COUNTRIES[0].slug),
        focal_length: get(item, 'focal_length', 2.8),
        vertical_fov: get(item, 'vertical_fov', 72),
        horizontal_fov: get(item, 'horizontal_fov', 130),
        plane_normal_x: get(item, 'plane_normal[0]', 0),
        plane_normal_y: get(item, 'plane_normal[1]', 0),
        plane_normal_z: get(item, 'plane_normal[2]', 0),
        plane_point_x: get(item, 'plane_point[0]', 0),
        plane_point_y: get(item, 'plane_point[1]', 0),
        plane_point_z: get(item, 'plane_point[2]', 0),

        hsafety_setting: {
            hivis_ppe_criteria: get(item, 'hsafety_setting.hivis_ppe_criteria', false),
            hardhat_ppe_criteria: get(item, 'hsafety_setting.hardhat_ppe_criteria', false),
            temporary_speed_limit: get(item, 'hsafety_setting.temporary_speed_limit', ''),
            heavy_plant_to_public_safety_distance_enabled: get(item, 'hsafety_setting.heavy_plant_to_public_safety_distance_enabled', false),
            heavy_plant_to_public_safety_distance: get(item, 'hsafety_setting.heavy_plant_to_public_safety_distance', null),
            heavy_plant_to_worker_safety_distance_enabled: get(item, 'hsafety_setting.heavy_plant_to_worker_safety_distance_enabled', false),
            heavy_plant_to_worker_safety_distance: get(item, 'hsafety_setting.heavy_plant_to_worker_safety_distance', null),
            alert_on_increased_risk_level: get(item, 'hsafety_setting.alert_on_increased_risk_level', false),
            alert_on_volatile_vehicle_locus: get(item, 'hsafety_setting.alert_on_volatile_vehicle_locus', false),
            alert_on_horizontal_person: get(item, 'hsafety_setting.alert_on_horizontal_person', false),
        },
        violation_metrics: metrics,
    };
};

export const getDefaultCameraTagsState = (item = null) => {
    if (item === null) {
        return [];
    }

    const cameraLocationsSlugs = Object.values(CAMERA_LOCATIONS);
    return item.tags.filter((tag) => (
        !cameraLocationsSlugs.includes(tag.slug)
    ));
};

export const getCameraSaveData = (model) => {
    // we have only one ROI field and we need to update it to match the request
    const cameraModel = {
        ...model,
    };

    if ('plane_normal_x' in cameraModel || 'plane_normal_y' in cameraModel || 'plane_normal_z' in cameraModel) {
        cameraModel.plane_normal = [
            parseFloat(cameraModel.plane_normal_x) || 0,
            parseFloat(cameraModel.plane_normal_y) || 0,
            parseFloat(cameraModel.plane_normal_z) || 0,
        ];

        delete cameraModel.plane_normal_x;
        delete cameraModel.plane_normal_y;
        delete cameraModel.plane_normal_z;
    }

    if ('plane_point_x' in cameraModel || 'plane_point_y' in cameraModel || 'plane_point_z' in cameraModel) {
        cameraModel.plane_point = [
            parseFloat(cameraModel.plane_point_x) || 0,
            parseFloat(cameraModel.plane_point_y) || 0,
            parseFloat(cameraModel.plane_point_z) || 0,
        ];

        delete cameraModel.plane_point_x;
        delete cameraModel.plane_point_y;
        delete cameraModel.plane_point_z;
    }

    // temporarily set the app type based on func type
    if (!cameraModel.function_type) {
        cameraModel.app_type = CAMERA_APP_TYPES.HS;
    } else {
        cameraModel.app_type = CAMERA_APP_TYPES.CARPARK;
    }

    return cameraModel;
};

export const getHSCameraSaveData = (model) => {
    // we have only one ROI field and we need to update it to match the request
    const cameraModel = {
        ...model,
        app_type: CAMERA_APP_TYPES.HS,
    };

    const tempSpeedLimit = get(cameraModel, 'hsafety_setting.temporary_speed_limit');
    if (!tempSpeedLimit) {
        set(cameraModel, 'hsafety_setting.temporary_speed_limit', 0);
    }

    const publicSafetyDistanceEnabled = get(cameraModel, 'hsafety_setting.heavy_plant_to_public_safety_distance_enabled');
    if (!publicSafetyDistanceEnabled) {
        set(cameraModel, 'hsafety_setting.heavy_plant_to_public_safety_distance', null);
    }
    const workerSafetyDistanceEnabled = get(cameraModel, 'hsafety_setting.heavy_plant_to_worker_safety_distance_enabled');
    if (!workerSafetyDistanceEnabled) {
        set(cameraModel, 'hsafety_setting.heavy_plant_to_worker_safety_distance', null);
    }

    // convert the object back to array
    if (isPlainObject(cameraModel.violation_metrics)) {
        cameraModel.violation_metrics = Object.values(cameraModel.violation_metrics);
    }

    if ('plane_normal_x' in cameraModel || 'plane_normal_y' in cameraModel || 'plane_normal_z' in cameraModel) {
        cameraModel.plane_normal = [
            parseFloat(cameraModel.plane_normal_x) || 0,
            parseFloat(cameraModel.plane_normal_y) || 0,
            parseFloat(cameraModel.plane_normal_z) || 0,
        ];

        delete cameraModel.plane_normal_x;
        delete cameraModel.plane_normal_y;
        delete cameraModel.plane_normal_z;
    }

    if ('plane_point_x' in cameraModel || 'plane_point_y' in cameraModel || 'plane_point_z' in cameraModel) {
        cameraModel.plane_point = [
            parseFloat(cameraModel.plane_point_x) || 0,
            parseFloat(cameraModel.plane_point_y) || 0,
            parseFloat(cameraModel.plane_point_z) || 0,
        ];

        delete cameraModel.plane_point_x;
        delete cameraModel.plane_point_y;
        delete cameraModel.plane_point_z;
    }

    return cameraModel;
};
