<template>
    <div class="columns is-multiline">
        <div class="column is-12">
            <div class="content">
                <p>
                    <a
                        href="https://docs.google.com/presentation/u/0/d/1WMNKBYVDhwR3Ph51At4aps0XkNcEgI2OiIz51hMg0mQ/edit"
                        target="_blank"
                        rel="nofollow noopener"
                    >
                        Axis documentation
                    </a>
                </p>
            </div>
        </div>
        <div v-if="endpoint && isAdmin" class="column is-12">
            <b-field label="Unique HTTPS Post URL">
                <be-copy :copy-text="endpoint" expanded />
            </b-field>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BeCopy from '@/components/global/BeCopy';
import manageHSCameraMixin from '@/mixins/hs/manageHSCameraMixin';

export default {
    components: {
        BeCopy,
    },

    mixins: [
        manageHSCameraMixin,
    ],

    computed: {
        ...mapState('sites', ['camerasEndpoints']),
        ...mapState('user', {
            userRole: (state) => state.details.role,
        }),

        endpoint() {
            return this.camerasEndpoints[this.cameraId] || null;
        },

        isAdmin() {
            return this.userRole === 'ADMIN';
        },
    },

    watch: {
        cameraId: {
            handler() {
                this.loadEndpoint();
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('sites', ['loadCameraEndpoint']),

        loadEndpoint() {
            this.isLoading = true;
            this.loadCameraEndpoint({ cameraId: this.cameraId })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
